import {Link, navigate} from "gatsby"
import React from "react"
import Cookies from 'js-cookie'

import svg_logo_type from "src/../static/images/logo_type.svg"
import svg_logo from "src/../static/images/logo.svg"
import svg_search_button from "src/../static/images/search_button.svg"
import svg_menu_button from "src/../static/images/menu_button.svg"
import svg_menu_close_button from "src/../static/images/menu_close_button.svg"
import svg_share_button from "src/../static/images/share_button.svg"

import {Consumer} from "src/utils/contexts"

import {
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    PinterestShareButton,
    // RedditShareButton,
    // TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    // WhatsappShareButton,

    EmailIcon,
    FacebookIcon,
    LineIcon,
    PinterestIcon,
    // RedditIcon,
    // TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    // WhatsappIcon,
} from "react-share"

import "./index.css"

export default class Header extends React.Component {

    componentDidMount() {
        if(this.props.location.href.indexOf("search") !== -1) {
            this.searchButton.click()

            setTimeout(() => {
                this.searchInput.blur()
            }, 15)

            this.searchInput.value = decodeURI(this.props.location.search.replace("?q=", ""))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.href.indexOf("search") !== -1 && this.props.location.href.indexOf("search") === -1) {
            this.searchButton.click()
        }
    }

    focusTextInput() {
        setTimeout(() => {
            this.searchInput.focus()
        }, 10)
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const query = event.target.value
            navigate("/search?q=" + query)
        }
    }

    forceNavigateToHome = (functions) => {
        functions.closeHeaderTools()

        // if it's home, force reload
        if(this.props.location.pathname === "/") {
            // reset scroll position
            Cookies.remove("scroll_position")

            // force reload
            window.location.reload()
        }
    }

    render() {

        const current_url = this.props.location.href

        return (
            <Consumer>
                {({store, functions}) => (
                    <div id="header" className={store.open_menu ? "nav-open" : "nav-close"}>
                        <div id="header_wrapper">
                            <div id="logo_type" className="header-content">
                                {/*<Link to={"/"} onClick={functions.closeHeaderTools}>*/}
                                    <img src={svg_logo_type} alt={"千のおうち Home of Thousand"}/>
                                {/*</Link>*/}
                            </div>
                            <div id="logo" className="header-content">
                                <Link to={"/"} onClick={() => {this.forceNavigateToHome(functions)}}>
                                    <img src={svg_logo} alt={"千のおうち ロゴ Home of Thousand logo"}/>
                                </Link>
                            </div>
                            <div id="header_function" className="header-content">
                                <div id="search_box" className={store.open_search ? "open" : "close"} >
                                    <input className="search-text-input" type="text"
                                        placeholder={"記事を検索..."}
                                        onKeyPress={this.handleKeyPress}
                                        disabled={ ! store.open_search}
                                        // value={this.props.location.search.replace("?q=", "")}
                                        ref={(input) => {this.searchInput = input}}
                                    />
                                    <div className="search-button"
                                         onClick={() => {
                                            functions.onClickSearchButton()
                                            this.focusTextInput()
                                         }}
                                         role={"button"}
                                         ref={(button) => this.searchButton = button}
                                    >
                                        <img src={svg_search_button}
                                             alt={"search button"}
                                             style={store.open_search ? {display: "none"} : null}
                                        />
                                        <img src={svg_menu_close_button}
                                             className="search-close"
                                             alt="menu close"
                                             style={store.open_search ? null : {display: "none"}}
                                        />
                                    </div>
                                </div>
                                <div id="share_button" onClick={functions.onClickShareButton} role={"button"}>
                                    {store.open_share ? null :
                                        <img src={svg_share_button} alt={"share button"}/>}
                                    {store.open_share ?
                                        <img src={svg_menu_close_button} className="search-close"
                                             alt="share close"/> : null}
                                </div>
                                <div id="menu_button" onClick={functions.onClickMenuButton} role={"button"}>
                                    {store.open_menu ? null :
                                        <img src={svg_menu_button} className="open" alt="menu open"/>}
                                    {store.open_menu ?
                                        <img src={svg_menu_close_button} className="close"
                                             alt="menu close"/> : null}
                                </div>
                            </div>
                        </div>
                        <div id="header_share" className={store.open_share ? "open" : "close"}>
                            <ul>
                                <li>
                                    <FacebookShareButton url={current_url}>
                                        <FacebookIcon size={41} round/>
                                    </FacebookShareButton>
                                </li>
                                <li>
                                    <TwitterShareButton url={current_url}>
                                        <TwitterIcon size={41} round/>
                                    </TwitterShareButton>
                                </li>
                                <li>
                                    <LineShareButton url={current_url}>
                                        <LineIcon size={41} round/>
                                    </LineShareButton>
                                </li>
                                <li>
                                    <TumblrShareButton url={current_url}>
                                        <TumblrIcon size={41} round/>
                                    </TumblrShareButton>
                                </li>
                                <li>
                                    <PinterestShareButton url={current_url}>
                                        <PinterestIcon size={41} round/>
                                    </PinterestShareButton>
                                </li>
                                <li>
                                    <EmailShareButton url={current_url}>
                                        <EmailIcon size={41} round/>
                                    </EmailShareButton>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </Consumer>
        )
    }
}
