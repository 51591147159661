import React from "react"
import {Consumer} from "src/utils/contexts"

import svg_audio_play from "src/../static/images/audio_play.svg"
import svg_audio_stop from "src/../static/images/audio_stop.svg"

import "./index.css"

export default class SoundPlayer extends React.Component {

    state = {
        playing: false,
        sound_button: svg_audio_play
    }

    playSound = () => {
        if(this.state.playing === false) {
            this.player.play()

            this.setState({playing: true})
            this.setState({sound_button: svg_audio_stop})
        } else {
            this.player.pause();
            this.player.currentTime = 0

            this.setState({playing: false})
            this.setState({sound_button: svg_audio_play})
        }
    }

    render() {

        if(this.props.node === undefined) {
            return (<></>)
        }

        return (
            <Consumer>
                {({store, functions}) => (
                    <>
                        {(() => {
                            // change path of sound file
                            let sound_path
                            if (this.props.node.sound) {
                                sound_path = this.props.node.sound[0].url.replace("/uploads", store.config.api_uri + "/uploads")
                            } else {
                                return (<></>)
                            }

                            return (
                                <div className="audio-controller" key={this.props.node.id} onClick={this.playSound}>
                                    <img src={this.state.sound_button} alt={this.props.node.title} />
                                    <audio src={sound_path} preload={"none"} ref={ref => this.player = ref}/>
                                </div>
                            )

                        })()}
                    </>
                )}
            </Consumer>
        )
    }
}