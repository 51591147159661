import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { Consumer } from "src/utils/contexts"
import Masonry from 'react-masonry-component'

import "./index.css"

export default class TagList extends React.Component {
    render() {
        return <StaticQuery
                    query={graphql`
                        query TagListQuery {
                                allStrapiTag(
                                limit: 10
                                filter: {
                                    visible: {eq: true}
                                }
                            ) {
                                edges {
                                    node {
                                        id
                                        Name
                                    }
                                }
                            }
                        }
                    `}
                    render={data => (
                        <Masonry
                            className={"nct-list"}
                            elementType={'ul'}
                            options={{
                                itemSelector: '.nct-item',
                                columnWidth: 1,
                                gutter: 18,
                                horizontalOrder: true,
                                originTop: false,
                                originLeft: false
                            }}
                        >
                            {data.allStrapiTag.edges.map(({node}) => {
                                return <li className="nct-item" key={"tag_" + node.id}>
                                    <Consumer>
                                        {({siteState, functions}) => (
                                            <Link to={"/tag/" + node.Name} onClick={functions.closeHeaderTools}>{node.Name}</Link>
                                        )}
                                    </Consumer>
                                </li>
                            })}
                        </Masonry>
                    )}
                />
    }
}
