import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Masonry from 'react-masonry-component'

import SoundPlayer from "src/components/Domain/Layout/sound_player"
import "./index.css"

export default class SoundList extends React.Component {
    render() {
        return <StaticQuery
                    query={graphql`
                        query SoundListQuery {
                                allStrapiSound(
                                limit: 10
                                filter: {
                                    visible: {eq: true}
                                }
                            ) {
                                edges {
                                    node {
                                        id
                                        title
                                        sound {
                                            url
                                        }
#                                        visible
                                    }
                                }
                            }
                        }
                    `}
                    render={data => (
                        <Masonry
                            className={"ncs-list"}
                            elementType={'ul'}
                            options={{
                                itemSelector: '.ncs-item',
                                originTop: false
                            }}
                        >
                            {data.allStrapiSound.edges.map(({node}) => {
                                return <li className="ncs-item" key={node.id}>
                                        <SoundPlayer node={node} />
                                        <span className={"sound-title"}>{node.title}</span>
                                    </li>
                            })}
                        </Masonry>
                    )}
                />
    }
}
