// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

// import "prismjs/themes/prism.css"

// exports.shouldUpdateScroll = () => { return false }
import 'lazysizes'

export const onClientEntry = async () => {
    if (!(`IntersectionObserver` in window)) {
        await import(`intersection-observer`);
    }
}