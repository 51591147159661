import React from "react"
import moment from "moment"

// For React Contexts API
const Context = React.createContext();

export class Provider extends React.Component {

    state = {
        open_search: false,
        open_share: false,
        open_menu: false,
        config: {
            // api_uri: 'https://api.s-e-n-n.jp',
            // api_uri: 'http://192.168.2.140:1337',
            api_uri: "https://api.s-e-n-n.jp",
            time_setting: {
                morning: {
                    start: moment('4:00', 'H:mm'),
                    end: moment('12:00', 'H:mm'),
                },
                day: {
                    start: moment('12:00', 'H:mm'),
                    end: moment('18:00', 'H:mm'),
                },
                night: {
                    start: moment('6:00', 'H:mm'),
                    end: moment('4:00', 'H:mm'),
                }
            }
        },
        time_interval: false,
        theme_class_name: null,
        scroll_y: 0,
        bg_fill: true,
        transition_finish: false
    }

    componentDidMount() {
        // アクセスした時間によって色をかえる
        this.changeTheme()

        // その後は1時間ごとに色をチェックする
        if(this.state.time_interval === false) {
            setInterval(() => {
                this.changeTheme()
            }, 5 * 60 * 1000, this)

            this.setState({time_interval: true})
        }
    }

    changeTheme = () => {
        const now = moment()
        const time_setting = this.state.config.time_setting

        if (now >= time_setting.morning.start && now < time_setting.morning.end) {
            // morning
            this.setState({theme_class_name: 'theme-morning'})
        } else if (now >= time_setting.day.start && now < time_setting.day.end) {
            // day
            this.setState({theme_class_name: 'theme-day'})
        } else if (now >= time_setting.night.start || now < time_setting.night.end) {
            // night
            this.setState({theme_class_name: 'theme-night'})
        }
    }

    functions = {
        onClickSearchButton: () => {
            const bool = !this.state.open_search
            this.setState({open_search: bool})
            if(bool) {
                this.functions.closeHeaderOtherTools("search")
            }
        },
        openSearch: () => {
            this.setState({open_search: true})
        },
        onClickShareButton: () => {
            const bool = !this.state.open_share
            this.setState({open_share: bool})
            if(bool) {
                this.functions.closeHeaderOtherTools("share")
            }
        },
        onClickMenuButton: () => {
            const bool = !this.state.open_menu

            if (this.state.open_menu === false) {
                // when it is going to open, remember scroll position
                this.setState({scroll_y: document.body.scrollTop || document.documentElement.scrollTop}, () => {
                    this.setState({
                        open_menu: bool
                    })
                })
            } else {
                // when it is going to close, restore scroll position
                this.setState({
                    open_menu: bool
                }, () => {
                    window.scrollTo(0, this.state.scroll_y)
                })
            }

            if(bool) {
                this.functions.closeHeaderOtherTools("menu")
            }
        },
        closeHeaderTools: () => {
            this.setState({open_search: false})
            this.setState({open_share: false})
            this.setState({open_menu: false})
        },
        closeHeaderOtherTools: (me) => {
            const flgs = {
                open_search: false,
                open_share: false,
                open_menu: false
            }

            const key = "open_" + me
            flgs[key] = true

            for(let k in flgs) {
                this.setState({[k]: flgs[k]})
            }
        },
        setConfig: (config) => {
            this.setState({config: config})
        },
        changeBgFill: (bool) => {
            if(this.state.bg_fill !== bool) {
                this.setState({bg_fill: bool})
            }
        },
        transitionFinishFlg: (bool) => {
            if(this.state.transition_finish !== bool) {
                this.setState({transition_finish: bool})
            }
            console.log(this.state.transition_finish)
        }

        // other functions here...
    }

    render() {
        const {children} = this.props

        // this.setState({
        //     store: store
        // })

        return (
            <Context.Provider value={{
                store: this.state,
                functions: this.functions
            }}>
                {children}
            </Context.Provider>
        )
    }
}

export const Consumer = Context.Consumer