import React from "react"
import "./index.css"

export default class PostListRelated extends React.Component {
    state = {
        copyright_show: false
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    componentDidMount() {
        document.addEventListener("scroll", this.trackScrolling)
        this.trackScrolling()
    }
    
    componentWillUnmount() {
        document.removeEventListener("scroll", this.trackScrolling)
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById("artcile_content")
        if( ! wrappedElement) {
            return false
        }
        this.isBottom(wrappedElement)
        if (this.isBottom(wrappedElement)) {
            // document.removeEventListener("scroll", this.trackScrolling)

            // show copyright
            this.showCopyright()
        } else {
            this.hideCopyright()
        }
    }

    showCopyright = () => {
        this.setState({copyright_show: true})
    }

    hideCopyright = () => {
        this.setState({copyright_show: false})
    }

    render() {
        return (
            <div id="copyright"
                 className={this.state.copyright_show ? "show" : ""}
                 style={this.props.location_path_array.length > 4 ? null : {display: "none"}}>
                <span>&copy; sennoouchi All Rights Reserved.</span>
            </div>
        )
    }
}
