import React from "react"

const svgBg = (location) => {
    return (
        <div className="svg-mask">
            <svg viewBox="0 0 1216.6 894.12">
                {/*<path style={{fill: "none"}} className="st0" d="M1965.6,123.5h-1366v418"/>*/}
                <path className="svg-mask-clipper" style={{fill: "none"}} d="M-729.5-61.38v2594.9H.5V890.42c0-335.7,272.1-607.8,607.8-607.8s607.8,272.1,607.8,607.8v1643.2h735.5v-2595Z"/>
            </svg>
        </div>
    )
}

export default svgBg