//Import necessary dependencies

import React from "react"
import {
    TransitionGroup,
    Transition as ReactTransition,
} from "react-transition-group"

const timeout = 200

const getTransitionStyles = {
    entering: {
        position: 'absolute',
        opacity: 0,
        willChange: 'opacity'
    },
    entered: {
        transition: `opacity ${timeout}ms ease`,
        opacity: 1,
    },
    exiting: {
        transition: `all ${timeout}ms ease`,
        opacity: 0
    },
}

class Transition extends React.Component {

    state = {
        prev_location_href: null
    }

    render() {
        const { children, location } = this.props

        return (
            <TransitionGroup>
                <ReactTransition
                    key={location.pathname}
                    timeout={{
                        enter: timeout,
                        exit: timeout,
                    }}
                >
                    {
                        status => (
                            <div
                                style={{
                                    ...getTransitionStyles[status],
                                }}
                            >
                                {children}
                            </div>
                        )}
                </ReactTransition>
            </TransitionGroup>
        )
    }
}

export default Transition
