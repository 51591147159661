import React from "react"

import Header from "src/components/Domain/Layout/header"
import Nav from "src/components/Domain/Layout/nav"
import Transition from "src/components/Element/trandition"
import Copyright from "src/components/Element/copyright"

import svgBg from "src/components/Element/svg_bg"

import "src/css/reset.css"
import "src/css/layout.css"
import "./index.css"

import {Consumer, Provider} from "src/utils/contexts"; // React Contexts API

export default class Layout extends React.Component {

    state = {
        header_shrink_class_name: null,
        loaded_class_name: null
    }

    // 背景のモードを変更する
    getBgFillOrNot = (store) => {
        if(store.bg_fill === true || store.open_menu === true) {
            return "bg-fill"
        }
    }

    render() {

        const location = this.props.location
        // const title = this.props.title
        const children = this.props.children
        let header_shrink_class_name = ""

        const location_path_array = location.pathname.split("/")
        if(
          (location_path_array[1] === "project" && location_path_array[2] !== "") ||
          (location_path_array.length > 4) ||
          (location_path_array[1] === "about") || (location_path_array[1] === "contact")
        ) {
            header_shrink_class_name = "shrink"
        } else {
            header_shrink_class_name = ""
        }

        return (
            <Provider>
                <Consumer>
                    {({store, functions}) => (
                        <>
                            {(() => {

                                // haikei mode
                                if(location.pathname === '/') {
                                    // home
                                    functions.changeBgFill(true)
                                } else {
                                    const path = location.pathname.split("/")

                                    if(path[1] === 'tag' || path[1] === 'search' || (path[1] === 'project' && ! path[2])) {
                                        functions.changeBgFill(true)
                                    } else {
                                        functions.changeBgFill(false)
                                    }
                                }

                                return (
                                    <>
                                        <div className={
                                            "site-wrapper " + store.theme_class_name + " " + header_shrink_class_name
                                            + " " + this.getBgFillOrNot(store)
                                        } >
                                            <Header location={location} />
                                            {svgBg(location)}
                                            <div id="main">
                                                <Nav/>
                                                <div id="main_inner" className={store.open_menu ? "menu-open" : "menu-close"}>
                                                    {/* <Transition location={location}> */}
                                                        {children}
                                                    {/* </Transition> */}
                                                </div>
                                            </div>
                                            <Copyright location_path_array={location_path_array} />
                                        </div>
                                    </>
                                )
                            })()}
                        </>
                    )}
                </Consumer>
            </Provider>
        )
    }
}

// export default Layout
