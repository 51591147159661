import {Link} from "gatsby"
import React from "react"

import {Consumer} from "src/utils/contexts"

import TagList from "src/components/Domain/Layout/tag_list"
import SoundList from "src/components/Domain/Layout/sound_list"

import svg_menu_header from "src/../static/images/menu_header.svg"
import "./index.css"

class Nav extends React.Component {

    // randomize the location of sound play icons
    componentDidMount() {
        const defaultMax = 1.4;

        // random buffer to give for spacing.
        // growth is inverse exponential, so larger is less likely
        const buffer = (min = 0.1, max = defaultMax, mult = 1) => {
            return Math.min(max, Math.max(min,
                min / 2 + Math.exp(Math.random() * 6) * Math.random() * mult / 12
            )) + "em"
        }

        const randomize = () => {
            const posts = document.getElementsByClassName("ncs-item");
            for (let p = 0; p < posts.length; p++) {
                // random buffered margins, ordered: top right bottom left.
                // top is at least 0.1em, right and bottom are at least 0.25em.
                // top and bottom are cut in half to limit lost vertical space.
                posts[p].style.margin = buffer(0.1, defaultMax, 0.5) + " "
                    + buffer(0.1) + " "
                    + buffer(0.25, defaultMax, 0.5) + " "
                    + buffer()
                // random width and height (with sane minimum size: 8em x 5em)
                // posts[p].style.width = buffer(8);
                // posts[p].style.height = buffer(5);
            }
        }

        randomize()
    }

    render() {
        return <Consumer>
            {({store, functions}) => (
                // <div id="nav" style={store.open_menu ? null : {height: 0, overflow: 'hidden'}}>
                <div id="nav" className={store.open_menu ? "open" : "hide"}>
                    <div className="nav-wrapper">
                        <div className="nav-content-menu">
                            <span className="menu-header-title"><img src={svg_menu_header} alt={"menu"} /></span>
                            <ul>
                                <li><Link className="tc-link" to="/about" onClick={functions.closeHeaderTools}>千のおうちとは？</Link></li>
                                <li><Link className="tc-link" to="/project" onClick={functions.closeHeaderTools}>プロジェクト</Link></li>
                                <li><Link className="tc-link" to="/contact" onClick={functions.closeHeaderTools}>コンタクト</Link></li>
                            </ul>
                        </div>
                        <div className="nav-content-tags">
                            <TagList />
                        </div>
                        <div className="nav-content-sounds">
                            <SoundList />
                        </div>
                    </div>
                </div>
            )}
        </Consumer>
    }
}

export default Nav
