// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-index-js": () => import("./../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-project-index-js": () => import("./../src/templates/project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-tag-index-js": () => import("./../src/templates/tag/index.js" /* webpackChunkName: "component---src-templates-tag-index-js" */),
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-index-js": () => import("./../src/pages/project/index.js" /* webpackChunkName: "component---src-pages-project-index-js" */),
  "component---src-pages-search-index-js": () => import("./../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */)
}

